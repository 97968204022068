@charset "utf-8";
/*---------------------------------------
page-error
----------------------------------------*/
h1 {
    padding: 2.66vw 0;
    text-align: center;
}

.errorBox {
    margin: 2.66vw 2.66vw 8.66vw;

    p {
        margin-bottom: 4.66vw;

        a {
            text-decoration: none;
        }
    }
}

.p-ctaArea__lead {
    font-size: 1.4rem;
}

.pg-errorArea_item {
    margin: 4.66vw auto;
    width: 35vw;
}

.pg-backArea__button {
    margin-bottom: 5.66vw;
    padding: 2.66vw;
    font-size: 1.85rem;
    background: #d4d4d4;
    box-shadow: 0 3px 0 #aaa;
    font-weight: bold;
}

.p-ctaArea--full {
    margin-bottom: 6.66vw;
}
